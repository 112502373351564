<template>
  <v-card>
    <v-card-text>
      <v-data-table :headers="headers" :items="methods" :items-per-page="50">
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template v-slot:item.enable="{ item }">
          <v-simple-checkbox color="green" :value="item.enable" />
        </template>
        <template v-slot:item.commission="{ item }">
          {{ item.commission }} %
        </template>
        <template v-slot:item.commission_add_amount="{ item }">
          {{ (item.commission_add_amount / 100).toFixed(2) }} Руб.
        </template>
        <template v-slot:item.minLimit="{ item }">
          {{ (item.minLimit / 100).toFixed(2) }} Руб.
        </template>
        <template v-slot:item.maxLimit="{ item }">
          {{ (item.maxLimit / 100).toFixed(2) }} Руб.
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import PaymentsMethodsService from "@/services/payments.method.service";

export default {
  name: "Methods",
  data: () => ({
    methods: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "id", value: "id" },
      { text: "System", value: "paymentSystem.title" },
      { text: "Направление", value: "paymentCode.title" },
      { text: "Способ", value: "paymentWay.name" },
      { text: "Валюта", value: "currency.name" },
      { text: "Комиссия", value: "commission" },
      { text: "Комиссия+", value: "commission_add_amount" },
      { text: "Мин. Лимит", value: "minLimit" },
      { text: "Макс. Лимит", value: "maxLimit" },
      { text: "Включено", value: "enable" },
      { text: "Дата создания", value: "createdAt" }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  methods: {
    getPaymentsMethods() {
      PaymentsMethodsService.findMany({
        include: {
          paymentCode: true,
          currency: true,
          paymentSystem: true,
          paymentWay: true
        }
      }).then(
        data => {
          this.methods = data;
        },
        error => {
          this.$noty.error(error.response.message);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  },
  created() {
    this.getPaymentsMethods();
  }
};
</script>

<style scoped></style>
