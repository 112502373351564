import { api } from "@/instances/http";

class PaymentsSystems {
  findMany(params = {}) {
    return api
      .post("/payments/systems/findMany", { ...params })
      .then(response => {
        return response.data;
      });
  }
  update(params = {}) {
    return api.post("/paymentsMethod/update", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params = {}) {
    return api.post("/paymentsMethod/update", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params = {}) {
    return api
      .post("/paymentsMethod/findFirst", { ...params })
      .then(response => {
        return response.data;
      });
  }
}

export const paymentSystemService = new PaymentsSystems();
