<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-center text-h4 col-12"
        >Настройка платежных методов</v-col
      >
    </v-row>
    <v-tabs v-model="tab">
      <v-tab>Методы</v-tab>
      <v-tab>Системы</v-tab>
      <v-tab>Способы</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Methods />
      </v-tab-item>
      <v-tab-item>
        <Systems />
      </v-tab-item>
      <v-tab-item>
        <Ways />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Methods from "@/views/pages/PaymentsMethod/Methods";
import Systems from "@/views/pages/PaymentsMethod/Systems";
import Ways from "@/views/pages/PaymentsMethod/Ways";
export default {
  name: "Index",
  components: {
    Methods,
    Systems,
    Ways
  },
  data: () => ({
    tab: 0
  })
};
</script>

<style scoped></style>
